export interface IEnvDataConfig {
  spaApiUrl: string;
  foo: string;
}


export let envData: IEnvDataConfig | null = null;

export async function loadEnvData(): Promise<void> {
  try {
    console.log('load data', process.env.VUE_APP_RUNTIME_CONFIG_PATH)
    const response = await fetch(process.env.VUE_APP_RUNTIME_CONFIG_PATH);
    if (!response.ok) {
      throw new Error(`Failed to load config: ${response.statusText}`);
    }
    envData = await response.json() as IEnvDataConfig;
  } catch (error) {
    console.error("Failed to load environment data:", error);
  }
  console.log("Loaded envData:", envData);
}
