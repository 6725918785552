import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "searchTile__bubble" }
const _hoisted_3 = { class: "searchTile__title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "searchTile__description" }
const _hoisted_7 = ["src"]

import {ISearchType} from '@/views/helpers/SearchTypes'
import {onBeforeMount, ref} from 'vue'
import {usePermissionStore} from '@/stores/permissionStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectSearchTile',
  props: {
    search_type: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const hasPermission = ref(false)
onBeforeMount(async () => {
	if (props.search_type.permission_string !== null) {
		const permissionStore = usePermissionStore()
		hasPermission.value = await permissionStore.userHasPermission(
			props.search_type.permission_string
		)
	}
})

function onClick() {
	if (!hasPermission.value) return
	emit('click')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "searchTile",
    disabled: !hasPermission.value,
    onClick: onClick
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.search_type.name === 'predoriX')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[0] || (_cache[0] = [
              _createTextVNode(" predori"),
              _createElementVNode("span", { class: "searchTile__title__X" }, "X", -1)
            ])))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.search_type.name), 1))
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.search_type.description), 1)
    ]),
    _createElementVNode("img", {
      src: `${props.search_type.icon_string}`,
      class: "searchTile__image"
    }, null, 8, _hoisted_7)
  ], 8, _hoisted_1))
}
}

})