import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu-list-container"
}
const _hoisted_2 = { class: "main-menu-list" }
const _hoisted_3 = { class: "main-menu-list main-menu-list-bottom" }
const _hoisted_4 = {
  key: 0,
  class: ""
}
const _hoisted_5 = { key: 1 }

import { inject, onBeforeMount, ref } from "vue";

	import { IPermissions, usePermissionStore } from "@/stores/permissionStore";
	import { useRoute, useRouter } from "vue-router";
	
export default /*@__PURE__*/_defineComponent({
  __name: 'AppMainMenu',
  setup(__props) {

	const permissionStore = usePermissionStore();
	const permissions = ref<IPermissions | null>(null);

	const eventBus = inject('eventBus')
	const initDone = ref(false);

	onBeforeMount(async () => {
		permissions.value = await permissionStore.getPermissionsList()
		initDone.value = true;
	});

	function showOverlay(overlayType: string) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		eventBus.$emit('show-component-overlay', overlayType)
	}

	const route = useRoute();

	function isActive(path){
		return route.path.startsWith(path);
	}
	function isActiveRoot(){
		return route.path === '/';
	}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[10] || (_cache[10] = _createElementVNode("div", {
      class: "main-menu-toggle-container",
      id: "main-menu-toggle"
    }, [
      _createElementVNode("div", { class: "menu-toggle main-menu-toggle" }, [
        _createElementVNode("div", { class: "menu-toggle-border-container" }, [
          _createElementVNode("div", { class: "arrow" })
        ])
      ])
    ], -1)),
    (permissions.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            _createElementVNode("li", {
              class: _normalizeClass(["main-menu-list-item", { active: isActiveRoot()}]),
              id: "main-menu-list-item__quick-start"
            }, [
              _createVNode(_component_router_link, {
                class: "is-text-16",
                to: "/"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("i", { class: "icon icon-white icon-icon_rocket icon-size-main-menu" }, null, -1),
                  _createElementVNode("span", { class: "text" }, "Quick Start", -1)
                ])),
                _: 1
              })
            ], 2),
            _createElementVNode("li", {
              class: _normalizeClass(["main-menu-list-item", {active: isActive('/search/project_list')}]),
              id: "main-menu-list-item__my-projects"
            }, [
              _createVNode(_component_router_link, {
                "active-class": "active",
                class: "is-text-16",
                to: "/search/project_list"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "icon icon-white icon-icon_project_V2 icon-size-main-menu" }, null, -1),
                  _createElementVNode("span", { class: "text" }, "Projects", -1)
                ])),
                _: 1
              })
            ], 2),
            _createElementVNode("li", {
              class: _normalizeClass(["main-menu-list-item", {active: isActive('/search/folder_list')}]),
              id: "main-menu-list-item__my-folders"
            }, [
              _createVNode(_component_router_link, {
                class: "is-text-16",
                to: "/search/folder_list"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "icon icon-white icon-icon_smartfolder icon-size-main-menu" }, null, -1),
                  _createElementVNode("span", { class: "text" }, "Smart Folders", -1)
                ])),
                _: 1
              })
            ], 2),
            (permissions.value['MONITORING'])
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass([{active: isActive('/monitoring')}, "main-menu-list-item"]),
                  id: "main-menu-list-item__my-monitoring"
                }, [
                  _createVNode(_component_router_link, {
                    class: "is-text-16",
                    to: "/monitoring"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "icon icon-white icon-icon_createfoldermonitoring icon-size-main-menu" }, null, -1),
                      _createElementVNode("span", { class: "text" }, "Monitoring", -1)
                    ])),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true),
            (permissions.value['TAGS'])
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: _normalizeClass([{active: isActive('/augmentations/tag_list')}, "main-menu-list-item"]),
                  id: "main-menu-list-item__my-tags"
                }, [
                  _createVNode(_component_router_link, {
                    class: "is-text-16",
                    to: "/augmentations/tag_list"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "icon icon-white icon-icon_vouchert-Circle icon-size-main-menu" }, null, -1),
                      _createElementVNode("span", { class: "text" }, "Tags", -1)
                    ])),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("li", {
              class: _normalizeClass(["main-menu-list-item", {active: isActive('/user_team/team_list')}]),
              id: "main-menu-list-item__my-teams"
            }, [
              _createVNode(_component_router_link, {
                class: "is-text-16",
                to: "/user_team/team_list"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "icon icon-white icon-icon_team icon-size-main-menu" }, null, -1),
                  _createElementVNode("span", { class: "text" }, "Teams", -1)
                ])),
                _: 1
              })
            ], 2)
          ]),
          _createElementVNode("ul", _hoisted_3, [
            (permissions.value['BUG_REPORT'])
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                  _createElementVNode("a", {
                    id: "bugReportOverlayButton",
                    href: "#",
                    class: "is-text-16",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showOverlay('BugReportOverlay')), ["stop","prevent"]))
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("i", { class: "icon icon-white icon-icon_bug icon-size-main-menu" }, null, -1),
                    _createElementVNode("span", { class: "text" }, "Feedback", -1)
                  ]))
                ]))
              : _createCommentVNode("", true),
            (permissions.value['WISH_LIST'])
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                  _createElementVNode("a", {
                    id: "wishlistOverlayButton",
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (showOverlay('WishlistOverlay')), ["stop","prevent"])),
                    class: "is-text-16"
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("i", { class: "icon icon-white icon-icon_present icon-size-main-menu" }, null, -1),
                    _createElementVNode("span", { class: "text" }, "Wish List", -1)
                  ]))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})