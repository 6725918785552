<template>
	<div class="is-inline-block right-menu" id="right-menu-toggle">
		<div class="right-menu-toggle-container" @click="toggleRightNavbar()">
			<div class="menu-toggle right-menu-toggle">
				<div class="menu-toggle-border-container">
					<div class="arrow arrow-left">
						<!--<i class="icon icon-icon_chevron-left icon-size-14 icon-white"></i>-->
					</div>
				</div>
			</div>
		</div>
		<div class="sidebar-right-container">

<!--			max_step_search:  {{this.navbarStateJson.max_step_search}}&#45;&#45;<br>-->
<!--			max_state_compare: {{this.navbarStateJson.max_state_compare}}&#45;&#45;<br>-->
<!--			post_state_compare: {{this.navbarStateJson.post_state_compare}}&#45;&#45;<br>-->
<!--			last_post_state: {{this.navbarStateJson.last_post_state}}&#45;&#45;<br>-->
<!--			{{ renderAsDirty }}<br>-->
<!--			{{dirtyBeforeSave}}-->


			<SearchStepsRenderer
				v-if="navbarActive"
				v-bind:steps="nav_bar_steps"
				v-bind:navbarStates="navbarStateJson"
				v-bind:hasPostSaveBeforePageLeaveClass="postSaveBeforePageLeave"
				v-bind:renderAsDirty="renderAsDirty"
			></SearchStepsRenderer>
			<div id="sidebar-right-container-vue">
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import SearchStepsRenderer from "@/components/RightNavbar/SearchStepsRenderer.vue";

// @Get all of theis menu stuff to
export default defineComponent({
	name: 'RightNavbar',
	components: {SearchStepsRenderer},
	data: function () {
		return {
			navbarActive: true,
			quickHelpStateJson: {
				show_quick_help: false,
				search_type: 'Ultra Quick',
				search_step: '/search/search_input'
			},
			renderAsDirty: false,
			dirtyBeforeSave: false,


			nav_bar_steps: JSON.parse('[{\u0022type\u0022: \u0022menu_section\u0022, \u0022title\u0022: \u0022Search Process\u0022}, {\u0022type\u0022: \u0022menu_entry\u0022, \u0022search_step\u0022: \u0022search_input\u0022, \u0022label\u0022: \u0022Describe Idea\u0022, \u0022step_url\u0022: \u0022/search/search_input/3085\u0022, \u0022step_path\u0022: \u0022/search/search_input\u0022, \u0022id\u0022: \u0022describe_idea_url\u0022, \u0022max_step\u0022: 10, \u0022force_span\u0022: false}, {\u0022type\u0022: \u0022menu_entry\u0022, \u0022search_step\u0022: \u0022result_list\u0022, \u0022label\u0022: \u0022Search Results\u0022, \u0022step_url\u0022: \u0022/search/result_list/3085\u0022, \u0022step_path\u0022: \u0022/search/result_list\u0022, \u0022id\u0022: \u0022search_results_url\u0022, \u0022max_step\u0022: 50}, {\u0022type\u0022: \u0022menu_section\u0022, \u0022title\u0022: \u0022Assessment Process\u0022}, {\u0022type\u0022: \u0022menu_entry\u0022, \u0022search_step\u0022: \u0022assesment_center_screen_results\u0022, \u0022label\u0022: \u0022Screen Results\u0022, \u0022step_url\u0022: \u0022/search/assesment_center_screen_results/3085\u0022, \u0022step_path\u0022: \u0022/search/assesment_center_screen_results\u0022, \u0022id\u0022: \u0022screen_results_url\u0022, \u0022max_step\u0022: 50, \u0022custom_class\u0022: \u0022pd\u002Dassessment\u002Dprocess\u002Dlink\u0022, \u0022force_span\u0022: true}, {\u0022type\u0022: \u0022menu_entry\u0022, \u0022search_step\u0022: \u0022assesment_center_final_results\u0022, \u0022label\u0022: \u0022Final Results\u0022, \u0022step_url\u0022: \u0022/search/assesment_center_final_results/3085\u0022, \u0022step_path\u0022: \u0022/search/assesment_center_final_results\u0022, \u0022id\u0022: \u0022detailed_view_url\u0022, \u0022max_step\u0022: 50, \u0022custom_class\u0022: \u0022pd\u002Dassessment\u002Dprocess\u002Dlink\u0022, \u0022force_span\u0022: true}]'),

			navbarStateJson: {
				'max_step_search': 10,
				'at_step_search': '/search/search_input',
				'at_step_search_number': 10,
				'last_post_state': 0,
				'post_state_compare': 0,
				'max_state_compare': 10,
				'search_type': 'Ultra Quick'
			},

			postSaveBeforePageLeave: 1
		}
	},
	created() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.$eventBus.$on('is_page_dirty', (is_dirty) => {
			// console.log('retrieve dirty', is_dirty);
			this.renderAsDirty = is_dirty;

			// eslint-disable-next-line no-undef
			IS_PAGE_LEAVE_ACTIVE = is_dirty;
		});

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.$eventBus.$on('remember-dirty-before-save', () => {
			// console.log('set-dirty-before-save', )
			this.dirtyBeforeSave = this.renderAsDirty;
			// console.log('set-dirty-before-save', this.dirtyBeforeSave)
		});


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
		this.$eventBus.$on('update-right-navbar-data', this.updateNavbarData);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
		this.$eventBus.$on('set-max-step', (maxStep: number, stepWasDirty) => {
			if (this.dirtyBeforeSave) {
				this.navbarStateJson.max_step_search = this.navbarStateJson.max_step_search < 50 ? maxStep : this.navbarStateJson.max_step_search;
				this.navbarStateJson.max_state_compare = this.navbarStateJson.max_state_compare < 50 ? maxStep : this.navbarStateJson.max_state_compare;

				this.navbarStateJson.post_state_compare = maxStep < 50 ? maxStep : 0 ;
				this.navbarStateJson.last_post_state = maxStep;
			}
		});

	},
	methods: {
		toggleRightNavbar() {
			const siteElement = document.getElementById('site-content') as HTMLElement;
			const className = 'right-menu-collapsed'
			const hasClass = siteElement.classList.contains(className);
			localStorage.setItem(className, (!hasClass).toString());
			if (hasClass) {
				siteElement.classList.remove(className)
			} else {
				siteElement.classList.add(className);
			}
		},


		updateNavbarData(navbarData: any) {
			if (navbarData['quickHelpStateJson'] !== undefined) {
				this.quickHelpStateJson = navbarData['quickHelpStateJson'];
			}

			this.postSaveBeforePageLeave = navbarData['postSaveBeforePageLeave'];
			this.navbarStateJson = navbarData['navbarStateJson'];
			this.nav_bar_steps = navbarData['nav_bar_steps'];

			this.navbarActive = true;
		},
	}
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
