import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "vue-close-button"
}
const _hoisted_3 = {
  id: "overlay-body",
  class: "overlay-body height-is-100"
}
const _hoisted_4 = { class: "content-scroller-assessment-overlay height-is-100" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["overlay-container maxHeight noMinHeight", {'with-max-width': _ctx.componentHasMaxwidth(_ctx.componentToRender), 'autoHeight': _ctx.componentHasAutoHeight(_ctx.componentToRender)}])
        }, [
          (!_ctx.componentHasNoAutoClose(_ctx.componentToRender))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("i", {
                  class: "pd-vue-overlay-close icon icon-icon_close icon-size-30",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentToRender), {
                param_container: _ctx.paramContainer,
                onClose: _ctx.close
              }, null, 40, ["param_container", "onClose"])),
              (_ctx.componentHasNoAutoClose(_ctx.componentToRender))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_ctx.componentHasNoAutoClose(_ctx.componentToRender))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("a", {
                            class: "is-cursor-pointer",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.jumpToLastPage && _ctx.jumpToLastPage(...args)))
                          }, _toDisplayString(_ctx.historyHasPreviousPage ? "Go Back to  last page" : "Go to Start Page"), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}