<script lang="ts" setup>
import SelectSearchTile from '@/components/dashboard/SelectSearchTile.vue'
import {ESearchTypes, ISearchType} from './helpers/SearchTypes'
import {fetchPOST} from './mixins/PredoriFetch'
import router from '@/router'
import {useRoute} from 'vue-router'
import {LoaderMixin} from './mixins/LoaderMixin'
const route = useRoute()
async function onClick(searchType: ISearchType) {
	LoaderMixin.methods.showLoader()
	if (searchType.submit_string == 'Predori X') {
		const url = '/spa/api/x/create'
		const res = await fetchPOST<{status: 'success' | 'error'; body: {id: number}}>({
			url: url,
		})
		LoaderMixin.methods.hideLoader()
		if (res == undefined) return
		if (res.status !== 'success') return
		router.push(`/x/${res.body.id}`)
	} else {
		const projectId = route.params.projectId
		let url = '/spa/api/search/select_search_type'
		if (projectId) {
			url = `${url}/${projectId}`
		}
		const data = {'Search-Type-Btn': searchType.submit_string}
		const res = await fetchPOST({url, body: data})
		if(res == undefined) LoaderMixin.methods.hideLoader
	}
}
</script>

<template>
	<div class="dashboard__wrapper">
		<div class="dashboard">
			<div class="dashboard__section">
				<div class="dashboard__section__header">
					<h2 class="dashboard__section__title is-text-headline">
						Welcome to the launch pad of your patent search
					</h2>
					<h3 class="dashboard__section__subtitle is-text-subline">
						Just select a search-type and start the predori search process to reveal the
						most relevant patent information
					</h3>
				</div>
				<div class="dashboard__searches">
					<div class="dashboard__searches__list">
						<SelectSearchTile
							v-for="(tile, idx) in ESearchTypes"
							:key="`st_${idx}`"
							:search_type="tile"
							@click="() => onClick(tile)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.dashboard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__wrapper {
		overflow-y: scroll;
		overflow-x: scroll;
		width: 100%;
		height: 100%;

		display: grid;
		place-items: center;
	}

	&__section {
		max-width: max-content;

		&__title {
			font-size: 28px;
			font-weight: bold;
			width: 100%;
		}

		&__header {
			max-width: 996px;
		}

		&__subtitle {
			margin-bottom: 32px;

			font-size: 24px;
			font-weight: bold;
		}
	}

	&__searches {
		max-width: 1236px;
		&__list {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: center;
		}
	}
}

@media (max-width: 1600px) {
	.dashboard {
		&__section {
			&__title {
				font-size: 24px;
			}

			&__header {
				max-width: 996px;
			}

			&__subtitle {
				font-size: 20px;
				margin-bottom: 28px;
			}
		}

		&__searches {
			max-width: 990px;
			&__list {
				gap: 10px;
			}
		}
	}
}
</style>
