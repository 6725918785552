<template>
  <div class="sidebar-entrys-container">
    <template v-for="step in steps" :key="step">
                <span v-if="step.type=='menu_section'" class="sidebar-entry sidebar-blue">
                    <div class="item-container">
                        <span class="item-label">
                             {{ step.title }}
                        </span>
                    </div>
                </span>

      <template v-else>
        <a v-if="renderAsLink(step)" v-on:click="openMenuOrTriggerLink($event, step.step_url)" :id="'right_nav' + step.id"   v-bind:href="step.step_url" class="pd-allow-pageleave  sidebar-entry sidebar-nav-item"
           v-bind:class="{'pd-post-save-before-leave':isFormDirty(),
                                        'nine-entrys': (navbarStates.search_type=='Technology Monitoring'),
                                        'ten-entrys': (navbarStates.search_type=='FTO'),
                                        'pd-post-save-before-leave': hasPostSaveBeforePageLeaveClass}"
        >
          <div class="item-container"
               v-bind:class="{'active':stepUrlsEqual(step.step_path, navbarStates.at_step_search),
                                            'navitem-status-current': isCurrentStep(step),
                                            'navitem-status-green': isFinishedStep(step) }"
          >
            <span class="item-label">{{step.label}}</span>
          </div>
        </a>
        <span v-else class="sidebar-entry sidebar-nav-item"
              v-bind:class="{'nine-entrys': (navbarStates.search_type=='Technology Monitoring'), 'ten-entrys': (navbarStates.search_type=='FTO') }"
        >
                        <div class="item-container"
                             v-bind:class="{'active':stepUrlsEqual(step.step_path, navbarStates.at_step_search),
                                            'navitem-status-current': isCurrentStep(step),
                                            'navitem-status-green': isFinishedStep(step) }"
                        >
                            <span class="item-label">{{step.label}}</span>
                        </div>
                    </span>
      </template>

    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {LoaderMixin} from "@/views/mixins/LoaderMixin";

// const RightNavbarButtonBus = new Vue();
export default defineComponent({
  name: 'SearchStepsRenderer',
  props: ['steps', 'navbarStates', 'hasPostSaveBeforePageLeaveClass', 'renderAsDirty'],
  mixins: [LoaderMixin],
  data: function () {
    return {
    }
  },
  methods: {
    isMenuOpen() {
      return true;
      // return $('.right-menu-collapsed').length === 0;
    },
    renderAsLink: function (step: any) {

      const force_dirty_span = this.renderAsDirty && step.max_step > this.navbarStates.at_step_search_number;

      // check if the step  url is the same as the current url, so if we are on the page already we can return false
      const path = this.$router.currentRoute.value.path;
      const urlWithoutID = path.substring(0, path.lastIndexOf("/"));
      if (urlWithoutID === step.step_path.replace('/spa/spa_index#', '')) {
        return false;
      }

      return !force_dirty_span &&
          !step.force_span &&
          step.step_url &&
          !(this.navbarStates.last_post_state < this.navbarStates.post_state_compare) &&
          step.max_step <= this.navbarStates.max_state_compare &&
          this.navbarStates.max_step_search >= step.max_step
    },
    // remove api and spa prefixes from urls
    redurceUrl(url: string) {
      url = url.replace('/spa/spa_index#/', '').replace('/spa/api/', '')
      if (url[0] === '/') {
          url = url.substr(1);
      }
      return url;
    },
    stepUrlsEqual(url1, url2) {
        // console.log('stepUrlsEqual', this.redurceUrl(url1), this.redurceUrl(url2) );
      return this.redurceUrl(url1) === this.redurceUrl(url2);
    },
    isCurrentStep: function(step:any) {
      const force_dirty_span = this.renderAsDirty && step.max_step >= this.navbarStates.at_step_search_number;
      if (force_dirty_span) {
        return this.navbarStates.at_step_search_number == step.max_step;
      } else {
				const assessment_center_steps = (this.navbarStates.max_state_compare >50) && step.max_step >= 50;
        // return step.max_step == this.navbarStates.max_step_search &&
        //     step.max_step == this.navbarStates.max_state_compare;
        return step.max_step == this.navbarStates.max_state_compare||assessment_center_steps;
      }

    },
    isFinishedStep: function(step:any) {
      const force_dirty_span = this.renderAsDirty && step.max_step > this.navbarStates.at_step_search_number;
      return  !force_dirty_span &&
          !this.isCurrentStep(step) &&
          step.max_step < this.navbarStates.max_state_compare &&
          this.navbarStates.max_step_search > step.max_step
    },
    isFormDirty: function () {
      return this.renderAsDirty;
    },
    openMenuOrTriggerLink(event:any, url: string) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (!this.isMenuOpen()) {
        const siteElement = document.getElementById('site-content');
        if (siteElement!=null) {
          siteElement.classList.remove('right-menu-collapsed');
        }
      } else {
          // eslint-disable-next-line no-undef
          if (!IS_PAGE_LEAVE_ACTIVE) {
              console.log('openMenuOrTriggerLink 11')
              // When search is not dirty just jump to the clicked url and return
              this.showLoader();
              this.$router.push(url.replace('/spa/spa_index#', ''))
              return;
          }
          console.log('openMenuOrTriggerLink 22')
          // When search was dirty, jump back to the old url and fix
          this.showLoader();

          // register callback when the save-data-silent is finished
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$eventBus.$once('save-data-silent-finished', () => {
              this.$router.push(url.replace('/spa/spa_index#', ''))
          });

          // start the save-data-silent
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.$eventBus.$emit('save-data-silent');
      }
    }
  },
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
