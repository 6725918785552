<script setup lang="ts">
	import { inject, onBeforeMount, ref } from "vue";

	import { IPermissions, usePermissionStore } from "@/stores/permissionStore";
	import { useRoute, useRouter } from "vue-router";
	const permissionStore = usePermissionStore();
	const permissions = ref<IPermissions | null>(null);

	const eventBus = inject('eventBus')
	const initDone = ref(false);

	onBeforeMount(async () => {
		permissions.value = await permissionStore.getPermissionsList()
		initDone.value = true;
	});

	function showOverlay(overlayType: string) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		eventBus.$emit('show-component-overlay', overlayType)
	}

	const route = useRoute();

	function isActive(path){
		return route.path.startsWith(path);
	}
	function isActiveRoot(){
		return route.path === '/';
	}

</script>

<template>
	<div class="main-menu-toggle-container" id="main-menu-toggle">
		<div class="menu-toggle main-menu-toggle">
			<div class="menu-toggle-border-container">
				<div class="arrow ">
				</div>
			</div>
		</div>
	</div>
	<div v-if="permissions" class="menu-list-container">
		<ul  class="main-menu-list">
			<li class="main-menu-list-item" :class="{ active: isActiveRoot()}" id="main-menu-list-item__quick-start">
				<router-link class="is-text-16" to="/">
					<i class="icon icon-white icon-icon_rocket icon-size-main-menu"></i>
					<span class="text">Quick Start</span>
				</router-link>
			</li>
			<li class="main-menu-list-item" :class="{active: isActive('/search/project_list')}" id="main-menu-list-item__my-projects">
				<router-link active-class="active" class="is-text-16" to="/search/project_list">
					<i class="icon icon-white icon-icon_project_V2 icon-size-main-menu"></i>
					<span class="text">Projects</span>
				</router-link>
			</li>
			<li class="main-menu-list-item" :class="{active: isActive('/search/folder_list')}" id="main-menu-list-item__my-folders">
				<router-link class="is-text-16" to="/search/folder_list">
					<i class="icon icon-white icon-icon_smartfolder icon-size-main-menu"></i>
					<span class="text">Smart Folders</span>
				</router-link>
			</li>
			<li v-if="permissions['MONITORING']" :class="{active: isActive('/monitoring')}" class="main-menu-list-item" id="main-menu-list-item__my-monitoring">
				<router-link class="is-text-16" to="/monitoring">
					<i class="icon icon-white icon-icon_createfoldermonitoring icon-size-main-menu"></i>
					<span class="text">Monitoring</span>
				</router-link>
			</li>
			<li v-if="permissions['TAGS']" :class="{active: isActive('/augmentations/tag_list')}"  class="main-menu-list-item" id="main-menu-list-item__my-tags">
				<router-link class="is-text-16" to="/augmentations/tag_list">
					<i class="icon icon-white icon-icon_vouchert-Circle icon-size-main-menu"></i>
					<span class="text">Tags</span>
				</router-link>
			</li>
			<li class="main-menu-list-item" :class="{active: isActive('/user_team/team_list')}" id="main-menu-list-item__my-teams">
				<router-link class="is-text-16" to="/user_team/team_list">
					<i class="icon icon-white icon-icon_team icon-size-main-menu"></i>
					<span class="text">Teams</span>
				</router-link>
			</li>
		</ul>

		<ul class="main-menu-list main-menu-list-bottom">
			<li class="" v-if="permissions['BUG_REPORT']">
				<a id="bugReportOverlayButton" href="#" class="is-text-16" @click.stop.prevent="showOverlay('BugReportOverlay')">
					<i class="icon icon-white icon-icon_bug icon-size-main-menu"></i>
					<span class="text">Feedback</span>
				</a>
			</li>
			<li v-if="permissions['WISH_LIST']">
				<a id="wishlistOverlayButton" href="#" @click.stop.prevent="showOverlay('WishlistOverlay')" class="is-text-16">
					<i class="icon icon-white icon-icon_present icon-size-main-menu"></i>
					<span class="text">Wish List</span>
				</a>
			</li>
		</ul>
	</div>
</template>
