import { defineStore } from 'pinia';
import {  Ref, ref } from 'vue';
import { fetchGET } from '@/views/mixins/PredoriFetch';
import { IPermissions } from "@/stores/permissionStore";
import { ICompany } from "@/views/helpers/CompanyRepresentation";


export const useUserStore = defineStore('user_info', () => {
	const user: Ref<IUser | null> = ref(null);
	const userWithDetails: Ref<IUserWithDetails | null> = ref(null);

	async function getUser(): Promise<IUser | null> {
		if (user.value) return user.value;
		user.value = await fetchUser();
		return user.value;
	}

	async function getUserWithDetails(): Promise<IUserWithDetails | null> {
		if (userWithDetails.value) {
      return userWithDetails.value
		}
		userWithDetails.value = await fetchUserWithDetails();
		return userWithDetails.value;
	}

	function mapUserData(json: IUserResponse['user']): IUser {
		return {
			id: json.pk,
			company_id: json.company ?? null,
			first_name: json.first_name || '',
			last_name: json.last_name || '',
			email: json.email,
			is_active: json.is_active,
			is_admin_user: json.is_admin_user,
		};
	}

	async function fetchUser() {
		const url = '/spa/api/users/self'
		const json = await fetchGET<IUserResponse | { error: string }>({url: url})
		if (json === undefined) return null
		if ('error' in json) {
			const hash = window.location.hash
			let redirectUrl = '/account/login/'
			if (hash && hash !== '#/') {
				redirectUrl += '?next=' + hash
			}
			console.warn('INFO - res.redirect ::: Redirecting to :', redirectUrl)
			location.href = redirectUrl
			return null
		}
		return json ? mapUserData(json.user) : null;
	}

	async function fetchUserWithDetails(): Promise<IUserWithDetails | null> {
		const url = '/spa/api/users/self_detailed';
		const json = await fetchGET<IUserResponseWithDetails>({ url });
		if (!json) return null;

		return {
			...mapUserData(json.user),
			two_factor_auth_enabled: json.user.two_factor_auth_enabled,
			voucher_hash: json.user.voucher_hash,
			permissions: json.user.permissions,
			company: json.user.company
		};
	}

	function invalidateUser() {
		user.value = null;
		userWithDetails.value = null;
	}

	return { getUser, getUserWithDetails, invalidateUser};
});

export interface IUser {
	id: number;
	company_id: number | null;
	first_name: string;
	last_name: string;
	email: string;
	is_active: boolean;
	is_admin_user: boolean;
}

export interface IUserWithDetails extends IUser {
	two_factor_auth_enabled: boolean;
	voucher_hash: string|null
	permissions: IPermissions;
	company: ICompany;
}

interface IUserResponse {
	user: {
		pk: number;
		company?: number | null;
		first_name: string;
		last_name: string;
		email: string;
		is_active: boolean;
		is_admin_user: boolean;
	};
}

interface IUserResponseWithDetails extends IUserResponse {
	user: IUserResponse['user'] & {
		two_factor_auth_enabled: boolean;
		voucher_hash: string|null
		permissions: IPermissions;
		company: ICompany;
	};
}
