import {defineStore} from 'pinia'
import {IProjectList, IProject, ISearch, ITeamWithUsers} from '@/views/helpers/Management.js'
import {Ref, ref} from 'vue'
import {fetchPOST} from '@/views/mixins/PredoriFetch'
import {showSnackBarItem, snackBarHelper, SnackbarType} from '@/views/helpers/SnackbarHelper'
import {IUser} from '@/stores/userInfoStore'

export const useProjectStore = defineStore('project_list', () => {
	const projectList: Ref<IProjectList | null> = ref(null)
	const projectListCurrent: Ref<IProjectList | null> = ref(null)
	function setProjectList(to: IProjectList) {
		projectList.value = to
		if (!projectListCurrent.value) projectListCurrent.value = to
	}
	function setProjectListCurrent(to: IProjectList) {
		projectListCurrent.value = to
	}

	function deleteProject(id: number) {
		if (!projectList.value) return
		findAndDelete(id, projectList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (ptr.children[idx].project.id == id) {
					ptr.children.splice(idx, 1)
					return
				} else {
					findAndDelete(id, ptr.children[idx])
				}
			}
		}
	}

	function deleteSearch(id: number) {
		if (!projectList.value) return
		findAndDelete(id, projectList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						child.searches.splice(idx, 1)
						return
					}
				}
				findAndDelete(id, child)
			}
		}
	}

	function addProject(toId: number | null, project: IProject) {
		if (!projectList.value) return
		const newProject: IProjectList = {
			project: project,
			children: [],
			searches: [],
			parent: null,
		}

		if (!toId) {
			newProject.parent = projectList.value
			projectList.value.children.push(newProject)
		} else {
			appendToId(toId, newProject, projectList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					obj.parent = el
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}
	function moveProject(toId: number | null, projectListItem: IProjectList) {
		if (!projectList.value) return
		const newToId = toId !== null && toId >= 1 ? toId : null

		if (!newToId) {
			projectListItem.parent = projectList.value
			projectList.value.children.push(projectListItem)
		} else {
			appendToId(newToId, projectListItem, projectList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					obj.parent = el
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}

	function addSearch(search: ISearch) {
		if (!projectList.value) return
		appendToId(search.project, search, projectList.value)

		function appendToId(id: number, search: ISearch, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					if (!el.searches) {
						el.searches = [search]
					} else {
						el.searches.push(search)
					}
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, search, el)
			})
		}
	}

	function editSearch(id: number, value: {new_name?: string; new_location?: number}) {
		if (!projectList.value) return false
		return findAndEditSearch(id, projectList.value)

		function findAndEditSearch(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						if (value.new_location && value.new_location != child.project.id) {
							const search = child.searches.splice(idx, 1)[0]
							if (value.new_name != undefined) search.name = value.new_name
							search.project = value.new_location
							addSearch(search)
						} else {
							if (value.new_name != undefined)
								child.searches[idx].name = value.new_name
						}
						return true
					}
				}
				if (findAndEditSearch(id, child) == true) return true
			}
			return false
		}
	}

	async function shareProjectWithUser(project: IProject, emailToShare: string): Promise<boolean> {
		const url = `/spa/api/user_team/share_project_by_email/${project.id}`
		const formData = new FormData()
		formData.set('email', emailToShare)

		const response = await fetchPOST<any>({url, formData})

		if (response === undefined || response['status'] !== 'ok') {
			showSnackBarItem(
				snackBarHelper(
					'Error',
					'Something went wrong while sharing the project.',
					SnackbarType.ERROR
				)
			)
			return false
		}
		if (projectList.value === null) {
			return true
		}
		if (response['invitee']) {
			const invitee: IUser = response['invitee']
			project.shared_team_pending_invitations.push({
				user_pk: invitee.id,
				user_name: invitee.first_name + ' ' + invitee.last_name,
			})
		}
		return true
	}

	async function shareProjectWithTeam(project: IProject, team: ITeamWithUsers): Promise<boolean> {
		const url = `/spa/api/user_team/share_project_to_team_ajax/${project.id}`
		const formData = new FormData()
		formData.set('team', team.id.toString())

		const response = await fetchPOST<any>({url, formData})
		if (response === undefined || response['status'] !== 'ok') {
			showSnackBarItem(
				snackBarHelper(
					'Error',
					'Something went wrong while sharing the project.',
					SnackbarType.ERROR
				)
			)
			return false
		}
		if (projectList.value === null) {
			return true
		}
		// console.log('debug', projectList.value.project, project)
		if (response['status'] === 'ok') {
			project.custom_team_shares.push({team_pk: team.id, team_name: team.name})
		}
		return true
	}

	function editProject(
		id: number,
		value: {name?: string; client?: string; number?: string; parent_project?: number}
	) {
		if (!projectList.value) return false
		findAndEditProject(id, projectList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					if (value.name != undefined) project.name = value.name
					if (value.client != undefined) project.client = value.client
					if (value.number != undefined) project.number = value.number
					if (
						value.parent_project !== undefined &&
						value.parent_project != project.parent_project
					) {
						project.parent_project = value.parent_project
						moveProject(value.parent_project, ptr.children[idx])
						ptr.children.splice(idx, 1)
					}
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}

	function removeUserShare(projectId: number, userId: number) {
		if (!projectList.value) return false
		findAndEditProject(projectId, projectList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					const shareIdx = project.shared_team_shares.findIndex(
						(el) => el.user_pk == userId
					)
					if (shareIdx <= -1) return false
					project.shared_team_shares.splice(shareIdx, 1)
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}
	function removeTeamShare(projectId: number, teamId: number) {
		if (!projectList.value) return false
		findAndEditProject(projectId, projectList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					const shareIdx = project.custom_team_shares.findIndex(
						(el) => el.team_pk == teamId
					)
					if (shareIdx <= -1) return false
					project.custom_team_shares.splice(shareIdx, 1)
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}
	function removeSelfShare(projectId: number) {
		deleteProject(projectId)
	}

	return {
		projectList,
		setProjectList,
		projectListCurrent,
		setProjectListCurrent,
		deleteProject,
		deleteSearch,
		addProject,
		addSearch,
		editSearch,
		editProject,
		shareProjectWithUser,
		shareProjectWithTeam,
		moveProject,
		removeSelfShare,
		removeTeamShare,
		removeUserShare,
	}
})

export const useProjectListActiveId = defineStore('project_active_id', () => {
	const activeId: Ref<number | null> = ref(null)
	function setActiveId(to: number | null) {
		activeId.value = to
	}

	return {activeId, setActiveId}
})
