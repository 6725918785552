<script setup lang="ts">
import { inject, onBeforeMount, ref } from "vue";
import { IPermissions, usePermissionStore } from "@/stores/permissionStore";
import { IUserWithDetails, useUserStore } from "@/stores/userInfoStore";
import { useInfoCenterMessageStore } from "@/stores/InfoCenterMessageStore";
import { envData } from "../../envData";

const eventBus = inject('eventBus')
const initDone = ref(false);
const permissionStore = usePermissionStore();
const permissions = ref<IPermissions | null>(null);

const messageStore = useInfoCenterMessageStore();
const unreadMessages = ref<number>(0);

const contextUser = ref<IUserWithDetails | null>(null)

onBeforeMount(async () => {
	const userStore = useUserStore();
	contextUser.value = await userStore.getUserWithDetails();

	permissions.value = await permissionStore.getPermissionsList()
	initDone.value = true;


	unreadMessages.value = await messageStore.getUnreadMessagesCount();

	messageStore.$subscribe(async () => {
		unreadMessages.value = await messageStore.getUnreadMessagesCount();
	});

});

function showOverlay(overlayType: string) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	eventBus.$emit('show-component-overlay', overlayType)
}
</script>

<template>
	<div v-if="permissions && permissions['COMPANY_ADMIN']" class="action-bar-dropdown dropdown">
		<button class="action-bar-dropdown__toggle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="action-bar-dropdown__icon icon icon-white icon-icon_Company icon-size-main-menu"></i>
			<span class="action-bar-dropdown__title">{{contextUser?.company.name}}</span>
			<i class="action-bar-dropdown__icon fas fa-caret-down"></i>
		</button>
		<ul class="action-bar-dropdown__content dropdown-menu" aria-labelledby="dropdownMenuButton">
			<li class="action-bar-dropdown__item">
				<router-link class="action-bar-dropdown__link dropdown-item" to="/company">Company Overview</router-link>
			</li>
			<li class="action-bar-dropdown__item">
				<router-link class="action-bar-dropdown__link dropdown-item" to="/company/users">Account Management</router-link>
			</li>
			<li class="action-bar-dropdown__item">
				<router-link class="action-bar-dropdown__link dropdown-item" to="/billing">Billing Management</router-link>
			</li>
			<li class="action-bar-dropdown__item" v-if="permissions && permissions['PREDORI_X']">
				<router-link class="action-bar-dropdown__link dropdown-item" to="/x/indexes">predoriX Indices</router-link>
			</li>
		</ul>
	</div>

	<div v-if="permissions && permissions['INFO_CENTER']" class="action-bar-button">
		<a @click.prevent.stop="showOverlay('InfoCenterOverlay')" id="info-center" title="Info Center" class="is-position-relative">
			<i class="icon icon-white icon-icon_envelope icon-size-main-menu"></i>
			<span v-if="unreadMessages>0" class="badge infocenter-badge pd-info-center-badge badge-ok">{{unreadMessages}}</span>
		</a>
	</div>

	<div class="action-bar-button">
		<a @click.prevent.stop="showOverlay('UserSettingOverlay')" id="settings-button" title="settings">
			<i class="icon icon-white icon-icon_settings icon-size-main-menu "></i>
		</a>
	</div>
	<div class="action-bar-button">
		<a title="Logout" :href="envData.spaApiUrl + '/user/logout'" class="topbar-link w-inline-block">
			<i class="icon icon-white icon-icon_log-out icon-size-main-menu"></i>
		</a>
	</div>
</template>

<style scoped lang="scss">

</style>
