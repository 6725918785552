import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logo" }
const _hoisted_2 = ["src"]

import image from "/public/images/predori_logo.png"

export default /*@__PURE__*/_defineComponent({
  __name: 'AppLogo',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _unref(image),
          alt: "predori Logo"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}
}

})