import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  class: "action-bar",
  id: "main-menu-action-bar"
}
const _hoisted_3 = {
  id: "site-content",
  class: "site-content"
}
const _hoisted_4 = {
  class: "is-inline-block main-menu",
  id: "main-menu-container"
}
const _hoisted_5 = { class: "is-inline-block main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppMainMenu = _resolveComponent("AppMainMenu")!
  const _component_OverlayComponentRenderer = _resolveComponent("OverlayComponentRenderer")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div id=\"pd-loader\" class=\"pd-loader pd-loader-extendable\" style=\"display:none;\"><div class=\"pd-loader-icon pd-loader-icon-start\"></div><div class=\"longLastingLoader\" style=\"padding:20px;display:none;\"><div><div class=\"iconContainer\"><div class=\"pd-loader-icon pd-loader-icon-extended\"></div></div><br><span class=\"is-text-large-bold is-text-align-center is-display-block\">Processing... We&#39;re making sure everything is ready for you.</span></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AppLogo),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AppHeader)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AppMainMenu)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_OverlayComponentRenderer),
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_router_view, { name: "right-menu-router" }),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "is-clearfix" }, null, -1))
    ])
  ], 64))
}