import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "is-inline-block right-menu",
  id: "right-menu-toggle"
}
const _hoisted_2 = { class: "sidebar-right-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchStepsRenderer = _resolveComponent("SearchStepsRenderer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "right-menu-toggle-container",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleRightNavbar()))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "menu-toggle right-menu-toggle" }, [
        _createElementVNode("div", { class: "menu-toggle-border-container" }, [
          _createElementVNode("div", { class: "arrow arrow-left" })
        ])
      ], -1)
    ])),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.navbarActive)
        ? (_openBlock(), _createBlock(_component_SearchStepsRenderer, {
            key: 0,
            steps: _ctx.nav_bar_steps,
            navbarStates: _ctx.navbarStateJson,
            hasPostSaveBeforePageLeaveClass: _ctx.postSaveBeforePageLeave,
            renderAsDirty: _ctx.renderAsDirty
          }, null, 8, ["steps", "navbarStates", "hasPostSaveBeforePageLeaveClass", "renderAsDirty"]))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("div", { id: "sidebar-right-container-vue" }, null, -1))
    ])
  ]))
}