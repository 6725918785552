<template>
	<!--		loader markup -->
	<div id="pd-loader" class="pd-loader pd-loader-extendable" style="display: none">
		<div class="pd-loader-icon pd-loader-icon-start"></div>
		<div class="longLastingLoader" style="padding: 20px; display: none;">
			<div>
				<div class="iconContainer">
					<div class="pd-loader-icon pd-loader-icon-extended"></div>
				</div><br>
				<span class="is-text-large-bold is-text-align-center is-display-block">Processing... We're making sure everything is ready for you.</span>
			</div>
		</div>
	</div>
	<!--		loader markup end loader markup-->

	<!-- {# @todo fix this custom vblock{% render_menu_classes %} {% block right_menu_is_renderd_class %}{% endblock %} #} -->
	<div class="header">
		<AppLogo />
		<div class="action-bar" id="main-menu-action-bar">
			<AppHeader />
		</div>
	</div>
	<div id="site-content" class="site-content">
		<div class="is-inline-block main-menu" id="main-menu-container">
			<AppMainMenu />
		</div>
		<div class="is-inline-block main-content">
			<OverlayComponentRenderer></OverlayComponentRenderer>
			<router-view />
		</div>
		<router-view name="right-menu-router"></router-view>
		<div class="is-clearfix"></div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// import PageLeaveWarningComponentt from "@/components/PageLeaveWarningComponent.vue";
import AppMainMenu from "@/components/layout/AppMainMenu.vue";
import OverlayComponentRenderer from "@/components/OverlayComponentRenderer.vue";
import AppHeader from "@/components/layout/AppHeader.vue";  // Import the component globally
import AppLogo from "@/components/layout/AppLogo.vue";

export default defineComponent({
	components: {
		AppHeader,
    AppLogo,
		OverlayComponentRenderer,
		AppMainMenu,
	}
});
</script>

<style lang="scss">
@use '@/assets/css/colors' as c;
.vue-notification {
	font-size: 16px !important;
	color: #ffffff !important;
	width: 400px !important;
	&.warn {
		background: #ffb648 !important;
		border-left-color: #f48a06 !important;
	}
}

.predori-notification {
	// margin: 0 5px 5px;
	padding: 10px;
	width: 420px !important;
	margin: 10px 10px 0 10px;
	position: absolute;
	top: 80px;
}
</style>
