import {defineStore} from 'pinia'
import {IProjectList, IProject, ISearch, ITeamWithUsers} from '@/views/helpers/Management.js'
import {Ref, ref} from 'vue'
import {fetchPOST} from '@/views/mixins/PredoriFetch'
import {showSnackBarItem, snackBarHelper, SnackbarType} from '@/views/helpers/SnackbarHelper'
import {IUser} from './userInfoStore'

export const useFolderStore = defineStore('folder_list', () => {
	const folderList: Ref<IProjectList | null> = ref(null)
	const folderListCurrent: Ref<IProjectList | null> = ref(null)
	function setFolderList(to: IProjectList) {
		folderList.value = to
		if (!folderListCurrent.value) folderListCurrent.value = to
	}
	function setFolderListCurrent(to: IProjectList) {
		folderListCurrent.value = to
	}

	function deleteFolder(id: number) {
		if (!folderList.value) return
		findAndDelete(id, folderList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (ptr.children[idx].project.id == id) {
					ptr.children.splice(idx, 1)
					return
				} else {
					findAndDelete(id, ptr.children[idx])
				}
			}
		}
	}

	function deleteCollection(id: number) {
		if (!folderList.value) return
		findAndDelete(id, folderList.value)

		function findAndDelete(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						child.searches.splice(idx, 1)
						return
					}
				}
				findAndDelete(id, child)
			}
		}
	}

	function addFolder(toId: number | null, folder: IProject) {
		if (!folderList.value) return
		const newFolder: IProjectList = {
			project: folder,
			children: [],
			searches: [],
			parent: null,
		}

		if (!toId) {
			newFolder.parent = folderList.value
			folderList.value.children.push(newFolder)
		} else {
			appendToId(toId, newFolder, folderList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					obj.parent = el
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}

	function moveFolder(toId: number | null, projectListItem: IProjectList) {
		if (!folderList.value) return
		const newToId = toId !== null && toId >= 1 ? toId : null

		if (!newToId) {
			projectListItem.parent = folderList.value
			folderList.value.children.push(projectListItem)
		} else {
			appendToId(newToId, projectListItem, folderList.value)
		}

		function appendToId(id: number, obj: IProjectList, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					obj.parent = el
					el.children.push(obj)
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, obj, el)
			})
		}
	}

	function addCollection(collection: ISearch) {
		if (!folderList.value) return
		appendToId(collection.project, collection, folderList.value)

		function appendToId(id: number, collection: ISearch, parent: IProjectList) {
			parent.children.forEach((el) => {
				if (el.project.id == id) {
					if (!el.searches) {
						el.searches = [collection]
					} else {
						el.searches.push(collection)
					}
					return
				}
				if (el.children.length < 1) {
					return
				}
				appendToId(id, collection, el)
			})
		}
	}

	function editCollection(id: number, value: {new_name?: string; new_location?: number}) {
		if (!folderList.value) return false
		return findAndEditCollection(id, folderList.value)

		function findAndEditCollection(id: number, ptr: IProjectList) {
			for (const child of ptr.children) {
				if (!child.searches) break
				for (let idx = 0; idx < child.searches.length; idx++) {
					if (child.searches[idx].id == id) {
						if (value.new_location && value.new_location != child.project.id) {
							const search = child.searches.splice(idx, 1)[0]
							if (value.new_name != undefined) search.name = value.new_name
							search.project = value.new_location
							addCollection(search)
						} else {
							if (value.new_name != undefined)
								child.searches[idx].name = value.new_name
						}
						return true
					}
				}
				if (findAndEditCollection(id, child) == true) return true
			}
			return false
		}
	}

	function editFolder(
		id: number,
		value: {
			name?: string
			client?: string
			number?: string
			parent_project?: number
			prdx_index?: null | {name: string; id: number}
		}
	) {
		if (!folderList.value) return false
		findAndEditProject(id, folderList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					if (value.name != undefined) project.name = value.name
					if (value.client != undefined) project.client = value.client
					if (value.number != undefined) project.number = value.number
					if (value.prdx_index !== undefined) project.prdx_index = value.prdx_index
					if (
						value.parent_project !== undefined &&
						value.parent_project != project.parent_project
					) {
						project.parent_project = value.parent_project
						moveFolder(value.parent_project, ptr.children[idx])
						ptr.children.splice(idx, 1)
					}
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}

	async function shareFolderWithUser(project: IProject, emailToShare: string): Promise<boolean> {
		const url = `/spa/api/user_team/share_project_by_email/${project.id}`
		const formData = new FormData()
		formData.set('email', emailToShare)

		const response = await fetchPOST<any>({url, formData})

		if (response === undefined || response['status'] !== 'ok') {
			showSnackBarItem(
				snackBarHelper(
					'Error',
					'Something went wrong while sharing the project.',
					SnackbarType.ERROR
				)
			)
			return false
		}
		if (folderList.value === null) {
			return true
		}
		if (response['invitee']) {
			const invitee: IUser = response['invitee']
			project.shared_team_pending_invitations.push({
				user_pk: invitee.id,
				user_name: invitee.first_name + ' ' + invitee.last_name,
			})
		}
		return true
	}

	async function shareFolderWithTeam(project: IProject, team: ITeamWithUsers): Promise<boolean> {
		const url = `/spa/api/user_team/share_project_to_team_ajax/${project.id}`
		const formData = new FormData()
		formData.set('team', team.id.toString())

		const response = await fetchPOST<any>({url, formData})
		if (response === undefined || response['status'] !== 'ok') {
			showSnackBarItem(
				snackBarHelper(
					'Error',
					'Something went wrong while sharing the project.',
					SnackbarType.ERROR
				)
			)
			return false
		}
		if (folderList.value === null) {
			return true
		}
		if (response['status'] === 'ok') {
			project.custom_team_shares.push({team_pk: team.id, team_name: team.name})
		}
		return true
	}

	function removeUserShare(projectId: number, userId: number) {
		if (!folderList.value) return false
		findAndEditProject(projectId, folderList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					const shareIdx = project.shared_team_shares.findIndex(
						(el) => el.user_pk == userId
					)
					if (shareIdx <= -1) return false
					project.shared_team_shares.splice(shareIdx, 1)
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}
	function removeTeamShare(projectId: number, teamId: number) {
		if (!folderList.value) return false
		findAndEditProject(projectId, folderList.value)

		function findAndEditProject(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					const shareIdx = project.custom_team_shares.findIndex(
						(el) => el.team_pk == teamId
					)
					if (shareIdx <= -1) return false
					project.custom_team_shares.splice(shareIdx, 1)
					return true
				}
				if (findAndEditProject(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}
	function removeSelfShare(projectId: number) {
		deleteFolder(projectId)
	}

	function setPrdxIndexVisually(folderId: number, prdx_index: null | {name: string, id: number}) {
		if (!folderList.value) return false
		return findAndSetPrdxIndex(folderId, folderList.value)

		function findAndSetPrdxIndex(id: number, ptr: IProjectList) {
			for (let idx = 0; idx < ptr.children.length; idx++) {
				if (!ptr.children[idx].project) return
				const project = ptr.children[idx].project
				if (project.id === id) {
					project.prdx_index = prdx_index
					return true
				}
				if (findAndSetPrdxIndex(id, ptr.children[idx]) == true) return true
			}
			return false
		}
	}

	return {
		folderList,
		setFolderList,
		folderListCurrent,
		setFolderListCurrent,
		deleteFolder,
		deleteCollection,
		addFolder,
		addCollection,
		editCollection,
		editFolder,
		moveFolder,
		shareFolderWithTeam,
		shareFolderWithUser,
		removeSelfShare,
		removeTeamShare,
		removeUserShare,
		setPrdxIndexVisually,
	}
})

export const useFolderListActiveId = defineStore('folder_active_id', () => {
	const activeId: Ref<number | null> = ref(null)
	function setActiveId(to: number | null) {
		activeId.value = to
	}

	return {activeId, setActiveId}
})
