import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "action-bar-dropdown dropdown"
}
const _hoisted_2 = {
  class: "action-bar-dropdown__toggle dropdown-toggle",
  type: "button",
  id: "dropdownMenuButton",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_3 = { class: "action-bar-dropdown__title" }
const _hoisted_4 = {
  class: "action-bar-dropdown__content dropdown-menu",
  "aria-labelledby": "dropdownMenuButton"
}
const _hoisted_5 = { class: "action-bar-dropdown__item" }
const _hoisted_6 = { class: "action-bar-dropdown__item" }
const _hoisted_7 = { class: "action-bar-dropdown__item" }
const _hoisted_8 = {
  key: 0,
  class: "action-bar-dropdown__item"
}
const _hoisted_9 = {
  key: 1,
  class: "action-bar-button"
}
const _hoisted_10 = {
  key: 0,
  class: "badge infocenter-badge pd-info-center-badge badge-ok"
}
const _hoisted_11 = { class: "action-bar-button" }
const _hoisted_12 = { class: "action-bar-button" }
const _hoisted_13 = ["href"]

import { inject, onBeforeMount, ref } from "vue";
import { IPermissions, usePermissionStore } from "@/stores/permissionStore";
import { IUserWithDetails, useUserStore } from "@/stores/userInfoStore";
import { useInfoCenterMessageStore } from "@/stores/InfoCenterMessageStore";
import { envData } from "../../envData";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const eventBus = inject('eventBus')
const initDone = ref(false);
const permissionStore = usePermissionStore();
const permissions = ref<IPermissions | null>(null);

const messageStore = useInfoCenterMessageStore();
const unreadMessages = ref<number>(0);

const contextUser = ref<IUserWithDetails | null>(null)

onBeforeMount(async () => {
	const userStore = useUserStore();
	contextUser.value = await userStore.getUserWithDetails();

	permissions.value = await permissionStore.getPermissionsList()
	initDone.value = true;


	unreadMessages.value = await messageStore.getUnreadMessagesCount();

	messageStore.$subscribe(async () => {
		unreadMessages.value = await messageStore.getUnreadMessagesCount();
	});

});

function showOverlay(overlayType: string) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	eventBus.$emit('show-component-overlay', overlayType)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (permissions.value && permissions.value['COMPANY_ADMIN'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "action-bar-dropdown__icon icon icon-white icon-icon_Company icon-size-main-menu" }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(contextUser.value?.company.name), 1),
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "action-bar-dropdown__icon fas fa-caret-down" }, null, -1))
          ]),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, {
                class: "action-bar-dropdown__link dropdown-item",
                to: "/company"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Company Overview")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "action-bar-dropdown__link dropdown-item",
                to: "/company/users"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Account Management")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "action-bar-dropdown__link dropdown-item",
                to: "/billing"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Billing Management")
                ])),
                _: 1
              })
            ]),
            (permissions.value && permissions.value['PREDORI_X'])
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    class: "action-bar-dropdown__link dropdown-item",
                    to: "/x/indexes"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("predoriX Indices")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (permissions.value && permissions.value['INFO_CENTER'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showOverlay('InfoCenterOverlay')), ["prevent","stop"])),
            id: "info-center",
            title: "Info Center",
            class: "is-position-relative"
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("i", { class: "icon icon-white icon-icon_envelope icon-size-main-menu" }, null, -1)),
            (unreadMessages.value>0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(unreadMessages.value), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (showOverlay('UserSettingOverlay')), ["prevent","stop"])),
        id: "settings-button",
        title: "settings"
      }, _cache[9] || (_cache[9] = [
        _createElementVNode("i", { class: "icon icon-white icon-icon_settings icon-size-main-menu" }, null, -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("a", {
        title: "Logout",
        href: _unref(envData).spaApiUrl + '/user/logout',
        class: "topbar-link w-inline-block"
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("i", { class: "icon icon-white icon-icon_log-out icon-size-main-menu" }, null, -1)
      ]), 8, _hoisted_13)
    ])
  ], 64))
}
}

})