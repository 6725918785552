<script lang="ts" setup>
import {ISearchType} from '@/views/helpers/SearchTypes'
import {onBeforeMount, ref} from 'vue'
import {usePermissionStore} from '@/stores/permissionStore'

const props = defineProps<{
	search_type: ISearchType,
}>()
const emit = defineEmits<{
	(e: 'click'): void
}>()

const hasPermission = ref(false)
onBeforeMount(async () => {
	if (props.search_type.permission_string !== null) {
		const permissionStore = usePermissionStore()
		hasPermission.value = await permissionStore.userHasPermission(
			props.search_type.permission_string
		)
	}
})

function onClick() {
	if (!hasPermission.value) return
	emit('click')
}
</script>

<template>
	<button class="searchTile" :disabled="!hasPermission" @click="onClick">
		<div class="searchTile__bubble">
			<div class="searchTile__title">
				<span v-if="search_type.name === 'predoriX'">
					predori<span class="searchTile__title__X">X</span>
				</span>
				<span v-else>
					{{ search_type.name }}
				</span>
			</div>
			<div class="searchTile__description">{{ search_type.description }}</div>
		</div>

		<img
			:src="`${props.search_type.icon_string}`"
			class="searchTile__image"
		/>
	</button>
</template>

<style lang="scss" scoped>
.searchTile {
	outline: none;
	border: none;
	overflow: hidden;
	position: relative;

	width: 300px;
	height: 300px;
	border-radius: 25px;
	background-color: t.$accent-blue;
	box-shadow: t.$elevation-4;

	&__bubble {
		position: absolute;
		inset: auto 0 0 0;
		z-index: 101;
		height: 200px;

		border-radius: 25px 25px 0 0;
		background-color: t.$background-1;

		transition: transform 0.2s ease-out;
		transform: translateY(138px);
	}

	&__image {
		position: absolute;
		inset: 0 0 20px 0;
		z-index: 100;
		width: 100%;
		height: 280px;
		transition: transform 0.2s ease-out;
		transform: scale(1.075);
		opacity: 0.5;
	}

	&__title {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 12px;
		padding-top: 12px;
		&__X {
			color: t.$accent-green;
			font-style: italic;
			font-weight: 950;
			font-size: 26px;
		}
	}
	&__description {
		padding: 0 8px;
		font-weight: 500;
	}

	&:not([disabled]):hover {
		.searchTile__bubble {
			transform: translateY(0px);
		}
		.searchTile__image {
			transform: scale(1);
		}
	}

	&[disabled] {
		background-color: t.$background-1;
		.searchTile {
			&__bubble {
				background-color: t.$background-3;
				color: t.$text-disabled;
			}

			&__image {
				opacity: 0.25;
			}

			&__title {
				color: t.$text-disabled;
			}
		}
	}
}

@media (max-width: 1600px) {
	.searchTile {
		width: 240px;
		height: 240px;

		&__bubble {
			height: 160px;
			transform: translateY(108px);
		}
		&__title {
			font-size: 20px;
			margin-bottom: 12px;
			padding-top: 10px;
		}
	}
}
</style>
