import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dashboard__wrapper" }
const _hoisted_2 = { class: "dashboard" }
const _hoisted_3 = { class: "dashboard__section" }
const _hoisted_4 = { class: "dashboard__searches" }
const _hoisted_5 = { class: "dashboard__searches__list" }

import SelectSearchTile from '@/components/dashboard/SelectSearchTile.vue'
import {ESearchTypes, ISearchType} from './helpers/SearchTypes'
import {fetchPOST} from './mixins/PredoriFetch'
import router from '@/router'
import {useRoute} from 'vue-router'
import {LoaderMixin} from './mixins/LoaderMixin'

export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const route = useRoute()
async function onClick(searchType: ISearchType) {
	LoaderMixin.methods.showLoader()
	if (searchType.submit_string == 'Predori X') {
		const url = '/spa/api/x/create'
		const res = await fetchPOST<{status: 'success' | 'error'; body: {id: number}}>({
			url: url,
		})
		LoaderMixin.methods.hideLoader()
		if (res == undefined) return
		if (res.status !== 'success') return
		router.push(`/x/${res.body.id}`)
	} else {
		const projectId = route.params.projectId
		let url = '/spa/api/search/select_search_type'
		if (projectId) {
			url = `${url}/${projectId}`
		}
		const data = {'Search-Type-Btn': searchType.submit_string}
		const res = await fetchPOST({url, body: data})
		if(res == undefined) LoaderMixin.methods.hideLoader
	}
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "dashboard__section__header" }, [
          _createElementVNode("h2", { class: "dashboard__section__title is-text-headline" }, " Welcome to the launch pad of your patent search "),
          _createElementVNode("h3", { class: "dashboard__section__subtitle is-text-subline" }, " Just select a search-type and start the predori search process to reveal the most relevant patent information ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ESearchTypes), (tile, idx) => {
              return (_openBlock(), _createBlock(SelectSearchTile, {
                key: `st_${idx}`,
                search_type: tile,
                onClick: () => onClick(tile)
              }, null, 8, ["search_type", "onClick"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})